const envVariables = {
    //*******************AWS configuration***********************/
    // awsBucket: 'nabhdlm', // production
    awsBucket: 'nabhdlm-testing', // testing
    signatureVersion: 'v4',
    accessKeyId: 'AKIAYZUO6EY3CGJTPFTP',
    secretAccessKey: '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',
    region: 'us-east-2',
    //*******************AWS configuration***********************/
    //************Remember to Put '/' at end of api**************/
    // baseURL: 'https://z0045efsg8.execute-api.ap-south-1.amazonaws.com/',
    // baseURL: 'https://5u6oa5wz9c.execute-api.ap-south-1.amazonaws.com/',
    // baseURL: 'http://localhost:8080/',
    baseURL: 'https://t983jexxte.ap-south-1.awsapprunner.com/',

    geminiURL: 'https://gemini-qna-api-42ctymu3wq-as.a.run.app/',
    awsBucketUrl: 'https://nabhdlm-testing.s3.us-east-2.amazonaws.com/testNabhdlm/',
    awsBucketFolder: 'testNabhdlm',
    awsBucketTestFolder: 'testNabhdlm/',
    awsBucketQuestionFolder: 'testNabhdlm/'
};

export default envVariables;
