// assets
import { IconBrandChrome, IconHelp } from '@tabler/icons';

// constant
const icons = { IconBrandChrome, IconHelp };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const teacher = {
    id: 'teacher',
    title: 'Teacher',
    type: 'group',
    children: [
        {
            id: 'create-question',
            title: 'Add Question',
            type: 'item',
            url: '/create-question',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        },
        {
            id: 'upload-questions',
            title: 'Upload Bulk Questions',
            type: 'item',
            url: '/upload-bulk-questions',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        },
        {
            id: 'list-question',
            title: 'View Questions',
            type: 'item',
            url: '/view-questions',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        },
        {
            id: 'create-test',
            title: 'Create Test',
            type: 'item',
            url: '/create-test',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        },
        {
            id: 'view-tests',
            title: 'View Tests',
            type: 'item',
            url: '/view-tests-details',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        },
        {
            id: 'students-test-records',
            title: 'Test Records',
            type: 'item',
            url: '/students-test-records',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        }
        // {
        //     id: 'documentation',
        //     title: 'Documentation',
        //     type: 'item',
        //     url: 'https://codedthemes.gitbook.io/berry/',
        //     icon: icons.IconHelp,
        //     external: true,
        //     target: true
        // }
    ]
};

export default teacher;
